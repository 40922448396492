import React, { useState, useEffect } from "react";
import { Button, Row, Col, Input, Table } from "antd";
import { useNavigate } from "react-router-dom";
import useGet from "../../../global/hooks/useGet";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "LicenseNo",
    dataIndex: "LicenseNo",
  },
  {
    title: "License Name",
    dataIndex: "LicenseName",
  },
  {
    title: "Product",
    dataIndex: "ProductName",
  },
  {
    title: "Model",
    dataIndex: "ModelName",
  },
  {
    title: "Type",
    dataIndex: "Type",
  },
  {
    title: "OEM",
    dataIndex: "OemName",
  },
];

const initialFilterState = {
  LicenseName: "",
  Product: "",
  Model: "",
  Type: "",
  OemNo: "",
};

export const LicenseUpgradeSelectForm = ({
  setSelectedRow,
  orderedLicenses,
}) => {
  const [listData, setListData] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [params, setParams] = useState();

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRows);
    },
  };

  const handleFilter = (e) => {
    const filterDict = {};
    for (const key in filter) {
      if (
        filter[key] !== null &&
        filter[key] !== "" &&
        filter[key] !== undefined
      ) {
        filterDict[key] = filter[key];
      }
    }
    const isEmpty = JSON.stringify(filterDict) === "{}";

    if (!isEmpty) {
      const params = {
        offset: 0,
        limit: 10,
        sort_by: "OemNo",
        order: "desc",
        LicenseName: filter.LicenseName,
        Product: filter.Product,
        Model: filter.Model,
        Type: filter.Type,
        OemNo: filter.OemNo,
      };
      setParams(params);
    }
  };

  const handleFilterChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setFilter({ ...filter, [inputName]: inputValue });
  };

  //   useEffect(() => {
  //     if (licenseData && !licenseDataLoading) {
  //       const filteredData = licenseData?.data?.filter(
  //         (item) => item?.LicenseType === type
  //       );
  //       const newData = filteredData?.map((el) => ({
  //         ...el,
  //         key: el?.LicenseNo,
  //       }));
  //       setListData(newData);
  //     }
  //   }, [licenseData, licenseDataLoading]);

  useEffect(() => {
    console.log(orderedLicenses);
    if (orderedLicenses) {
      const newData = orderedLicenses?.map((el) => ({
        ...el,
        key: el?.LicenseNo,
      }));
      setListData(newData);
    }
  }, [orderedLicenses]);

  console.log({ listData });

  return (
    <div className="">
      <div className="mt-3 ">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="LicenseName"
              placeholder="Name"
              value={filter.LicenseName}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              placeholder="Product"
              value={filter.Product}
              onChange={handleFilterChange}
              name="Product"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              placeholder="Model"
              value={filter.Model}
              onChange={handleFilterChange}
              name="Model"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              placeholder="Type"
              value={filter.Type}
              onChange={handleFilterChange}
              name="Type"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              placeholder="OEM"
              value={filter.OemNo}
              onChange={handleFilterChange}
              name="OemNo"
            />
          </Col>
        </Row>
      </div>

      <div className="my-4">
        <Button
          type="primary"
          // disabled={selectedRowKeys?.length === 0 ? true : false}
          onClick={handleFilter}
          className="mr-2"
        >
          <CheckCircleOutlined />
          Apply
        </Button>
        <Button
          type="primary"
          // disabled={selectedRowKeys?.length === 0 ? true : false}
          onClick={() => {
            setFilter(initialFilterState);
            const params = {
              limit: 10,
            };
            setParams(params);
          }}
        >
          <SyncOutlined />
          Reset
        </Button>
      </div>
      <Table
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        columns={columns}
        dataSource={listData}
      />
    </div>
  );
};
