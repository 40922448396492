import { Button, Col, Input, Row, Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import useGet from "../../../global/hooks/useGet";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import CustomDateTimePicker from "../../../global/components/inputWidgets/CustomDatePicker";

export const OrderFilter = ({
  filter,
  handleFilterChange,
  handleFilter,
  handleReset,
  setShowModal,
  selectedRowKeys,
  setFilter,
}) => {
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/order/getoptions`;
  const navigate = useNavigate();
  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedAccounts = getModifiedResponse(optionData, "items");

  return (
    <div className="mb-4">
      <div className="mt-2">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="OrderNo"
              placeholder="No."
              value={filter.OrderNo}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Account"
              optionFilterProp="AccountNo"
              value={filter.AccountNo || undefined}
              onChange={(value) => setFilter({ ...filter, AccountNo: value })}
              // onSearch={onSearch}
              options={modifiedAccounts}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="OrderName"
              placeholder="Name"
              value={filter.OrderName}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            {/* <Input
              className="input_text_3 mr10"
              placeholder="Order Time"
              value={filter.OrderTime}
              onChange={handleFilterChange}
              name="OrderTime"
            /> */}
            <CustomDateTimePicker
              name="OrderTime"
              placeholder="Order Time"
              format="YYYY-MM-DD hh:mm A"
              value={filter.OrderTime}
              onChange={(value) => setFilter({ ...filter, OrderTime: value })}
              // onChange={(value) => console.log(value)}
            />
          </Col>
        </Row>
      </div>
      <div className="fw fl flex overflow-x-auto ">
        <div className=" flex-shrink-0 mt-4">
          <Button
            type="primary"
            className="mr-2 w-[100px] h-[30px]"
            onClick={handleFilter}
          >
            <CheckCircleOutlined />
            Apply
          </Button>
          <Button className="reset-button" onClick={handleReset}>
            <SyncOutlined />
            Reset
          </Button>

          <Button
            type="primary"
            className="mr-2 w-[100px] h-[30px]"
            onClick={() => navigate("/order/add")}
          >
            <PlusOutlined />
            Add
          </Button>
          <Button
            type="primary"
            className="w-[100px] h-[30px]"
            disabled={selectedRowKeys?.length === 0 ? true : false}
            onClick={() => {
              setShowModal(true);
            }}
          >
            <DeleteOutlined />
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};
