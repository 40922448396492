import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { Form, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { showToast } from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  DesktopOutlined,
  FolderOpenOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import CustomCheckbox from "../../../global/components/inputWidgets/CustomCheckBox";
import usePut from "../../../global/hooks/usePut";
import { useNavigate } from "react-router-dom";
import { LicenseContext } from "../Context/LicenseProvider";

export const LicenseUpgrade = ({ type }) => {
  const [loggedInCustomer, setLoggedInCustomer] = useState();
  const [mergedLicense, setMergedLicense] = useState({});
  const data = sessionStorage.getItem("customerInfo");
  const customerInfo = JSON.parse(data);

  const { selectedLicense } = useContext(LicenseContext);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_API_URL}/api/initial/info`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/license`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/device/${customerInfo?.mac}`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/license/getoptions`;
  const usedLicenseUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/used`;

  const {
    data: deviceData,
    loading: deviceDataLoading,
    error: deviceDataError,
  } = useGet(putUrl);

  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedProducts = getModifiedResponse(optionData, "products");
  const modifiedModels = getModifiedResponse(optionData, "models");
  const modifiedOems = getModifiedResponse(optionData, "oem");
  const modifiedOp = getModifiedResponse(optionData, "options");

  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(usedLicenseUrl);

  const onFinish = (values) => {
    // console.log({ values });
    try {
      putAction(values);
      !putLoading &&
        showToast("License updated successfully!", "success", faCheckCircle);
      navigate("/newLicense");
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  // Function to merge licenses by finding the largest values and merging options
  const mergeLicenses = (licenses) => {
    const newLicense = {
      Product: Math.max(...licenses?.map((license) => license.Product)),
      Model: Math.max(...licenses?.map((license) => license.Model)),
      Type: Math.max(...licenses?.map((license) => license.Type)),
      OemNo: deviceData?.data?.OemNo,
      Camera: Math.max(...licenses?.map((license) => license.Camera)),
      Channel: Math.max(...licenses?.map((license) => license.Channel)),
      Lockset: Math.max(...licenses?.map((license) => license.Lockset)),
      Facegate: Math.max(...licenses?.map((license) => license.Facegate)),
      Subnode: Math.max(...licenses?.map((license) => license.Subnode)),
      ContLock: Math.max(...licenses?.map((license) => license.ContLock)),
      Intercom: Math.max(...licenses?.map((license) => license.Intercom)),
      Options: [...new Set(licenses?.flatMap((license) => license.Options))],
    };

    return newLicense;
  };

  useEffect(() => {
    setLoggedInCustomer(customerInfo);
  }, []);

  // useEffect to merge licenses when selectedLicense changes
  useEffect(() => {
    if (selectedLicense && deviceData && selectedLicense.length) {
      const licensesToCompare = [...selectedLicense, deviceData?.data];
      const newMergedLicense = mergeLicenses(licensesToCompare);
      setMergedLicense(newMergedLicense);
    }
  }, [selectedLicense, deviceData]);

  //setting initial vales
  useEffect(() => {
    if (deviceData && type === "info") {
      form.setFieldsValue({
        Mac: customerInfo?.mac,
        Product: deviceData?.data?.ProductName,
        Model: deviceData?.data?.ModelName,
        Type: String(deviceData?.data?.Type),
        Options: String(deviceData?.data?.OptionNames),
        OemNo: deviceData?.data?.OemName,
        Camera: String(deviceData?.data?.Camera),
        Channel: String(deviceData?.data?.Channel),
        Lockset: String(deviceData?.data?.Lockset),
        Facegate: String(deviceData?.data?.Facegate),
        Intercom: String(deviceData?.data?.Intercom),
        Subnode: String(deviceData?.data?.Subnode),
        ContLock: String(deviceData?.data?.ContLock),
      });
    } else {
      if (selectedLicense && mergedLicense) {
        form.setFieldsValue({
          Mac: customerInfo?.mac,
          Product: mergedLicense?.Product,
          Model: mergedLicense?.Model,
          Type: String(mergedLicense?.Type),
          Options: mergedLicense?.Options,
          OemNo: mergedLicense?.OemNo,
          Camera: String(mergedLicense?.Camera),
          Channel: String(mergedLicense?.Channel),
          Lockset: String(mergedLicense?.Lockset),
          Facegate: String(mergedLicense?.Facegate),
          Intercom: String(mergedLicense?.Intercom),
          Subnode: String(mergedLicense?.Subnode),
          ContLock: String(mergedLicense?.ContLock),
        });
      }
    }
  }, [deviceData, selectedLicense]);

  //send the order item no & mac
  useEffect(() => {
    if (putData && !putLoading && !putError) {
      const orderItemNumbers = selectedLicense
        .filter((item) => item?.ItemNo) // Filter out items that don't have OrderItemNo
        .map((item) => item.ItemNo); // Extract OrderItemNo

      const payload = {
        Mac: customerInfo?.mac,
        ItemNo: orderItemNumbers,
      };
      postAction(payload);
    }
  }, [putData, putLoading]);

  //navigate to upgrade page after license is upgraded
  useEffect(() => {
    if (postData && !postLoading && !postError) {
      sessionStorage.removeItem("customerInfo");
      navigate(
        `/upgrade?mac=${loggedInCustomer?.mac}&product=${loggedInCustomer.product}`
      );
    }
  }, [postData, postLoading]);

  const breadcrumbItems = [
    {
      label: "Upgrade",
      href: "/setUpgrade",
    },
    type === "info"
      ? {
          label: "Old License",
          href: "/setUpgrade",
        }
      : {
          label: "New License",
          href: "/newLicense",
        },
  ];

  const buttonsData =
    type === "info"
      ? [
          {
            label: "Next",
            icon: <ArrowRightOutlined />,
            onClick: () => {
              navigate("/orderedItems");
            },
          },
        ]
      : [
          {
            label: "Prev",
            icon: <ArrowLeftOutlined />,
            onClick: () => {
              navigate("/orderedItems");
            },
          },
          {
            label: "Apply",
            icon: <CheckCircleOutlined />,
            onClick: () => form.submit(),
          },
        ];

  return (
    <>
      <Form
        validateTrigger={"onChange"}
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        layout={"vertical"}
        autoComplete={"off"}
      >
        <div className="px-8 ">
          <div className="flex justify-between mt-8">
            <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
            <CommonButton buttonsData={buttonsData} />
          </div>
          {!deviceDataLoading && (
            <>
              <div className="mt-6 rounded-lg shadow-lg overflow-hidden">
                <div className="bg-gray-100 p-2">
                  <DesktopOutlined />
                  <span className="text-md ml-2">Device</span>
                </div>
                <div className="p-2">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput label="MAC" name="Mac" disable={true} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomSelect
                        label="Product"
                        name="Product"
                        options={modifiedProducts}
                        placeholder="Please select"
                        disabled={true}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomSelect
                        label="Model"
                        name="Model"
                        options={modifiedModels}
                        placeholder="Please select"
                        disabled={true}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput label="Type" name="Type" disabled={true} />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      {type === "info" ? (
                        <CustomInput
                          label="Options"
                          name="Options"
                          disable={true}
                        />
                      ) : (
                        <CustomCheckbox
                          label="Options"
                          name="Options"
                          options={modifiedOp}
                          disabled={true}
                        />
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomSelect
                        label="OEM"
                        name="OemNo"
                        options={modifiedOems}
                        placeholder="Please select"
                        disabled={true}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Camera"
                        name="Camera"
                        disabled={true}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Channel"
                        name="Channel"
                        disabled={true}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Lockset"
                        name="Lockset"
                        disabled={true}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Facegate"
                        name="Facegate"
                        disabled={true}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Subnode"
                        name="Subnode"
                        disabled={true}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Trilogy Lockset"
                        name="ContLock"
                        disabled={true}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Intercom"
                        name="Intercom"
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </div>
      </Form>
    </>
  );
};
