import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox } from "antd";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo/mainLogo.png";
import usePost from "../hooks/usePost";
import { showToast } from "../utils/toast";
import AuthLayout from "./AuthLayout";
import "../styles/login.css";
import usePostLogin from "../hooks/usePostLogin";

export const ResetPassword = () => {
  const postUrl = `${process.env.REACT_APP_API_URL}/api/resetpassword`;
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePostLogin(postUrl);

  const navigate = useNavigate();

  useEffect(() => {
    if (postData) {
      showToast("Password reset Successfully!", "success", faCheckCircle);
      navigate("/");
    }
    if (postError) {
      showToast(
        "Login failed. Please check your credentials and try again.",
        "error",
        faWarning
      );
    }
  }, [postData, postError]);

  // on submit handler.
  const onSubmitHandler = async (values) => {
    try {
      const updatedValues = {
        SecurityCode: values?.SecurityCode,
        NewPassword: values?.NewPassword,
      };
      await postAction(updatedValues);
    } catch (error) {
      showToast(
        "Login failed. Please check your credentials and try again.",
        "error",
        faWarning
      );
    }
  };

  return (
    <AuthLayout>
      <div className="border rounded-[4px] w-[418px] p-10 bg-white">
        <img src={logo} alt="Sicunet" className="w-[170px] mb-6 mx-auto" />
        <Form
          autoComplete="off"
          requiredMark={false}
          layout="vertical"
          onFinish={onSubmitHandler}
        >
          <Form.Item
            label="Security Code"
            name="SecurityCode"
            className="my-input"
            rules={[
              {
                required: true,
                message: "Please input your userId!",
              },
            ]}
          >
            <Input className="h-[32px] shadow-lg !bg-white" />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="NewPassword"
            className="my-input"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password className="h-[32px] shadow-lg !bg-white" />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="password"
            className="my-input"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password className="h-[32px] shadow-lg !bg-white" />
          </Form.Item>
          <div className="flex justify-center">
            <Form.Item className="mr-2">
              <Button
                htmlType="submit"
                className="min-w-[100px] bg-customColor text-white hover:!bg-white border-none hover:!text-customColor font-medium text-[14px] !rounded !py-4 h-[35px] "
              >
                Apply
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className="min-w-[100px] bg-[#E9E9E9] text-customColor hover:!bg-customColor hover:!text-white border-none font-medium text-[14px] !rounded !py-4 h-[35px]"
                onClick={() => navigate("/")}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </AuthLayout>
  );
};
