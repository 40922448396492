import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Form, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import CommonModal from "../../../global/components/CommonModal";
import {
  CheckCircleOutlined,
  DesktopOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import CustomCheckbox from "../../../global/components/inputWidgets/CustomCheckBox";
import usePut from "../../../global/hooks/usePut";
import { LicenseUpgradeSelectForm } from "../../Device/Components/LicenseUpgradeSelect";

export const LicenseCreation = ({ type }) => {
  const data = sessionStorage.getItem("customerInfo");
  const [showCommonModal, setShowCommonModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [customerInfo, setCustomerInfo] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_API_URL}/api/initial/info`;
  const orderedLicenseUrl = `${process.env.REACT_APP_API_URL}/api/license/ordered`;
  const licenseInfoUrl = `${process.env.REACT_APP_API_URL}/api/license/${selectedRow[0]?.LicenseNo}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/device/create`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/device/${customerInfo?.mac}`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/license/getoptions`;

  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const {
    data: individualLicenseData,
    loading: individualLicenseLoading,
    error: individualLicenseError,
    refetch,
  } = useGet(licenseInfoUrl);

  const modifiedProducts = getModifiedResponse(optionData, "products");
  const modifiedModels = getModifiedResponse(optionData, "models");
  const modifiedOems = getModifiedResponse(optionData, "oem");
  const modifiedOp = getModifiedResponse(optionData, "options");

  const {
    data: InfoData,
    loading: InfoLoading,
    error: InfoError,
    postData: InfoAction,
  } = usePost(url);
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  const {
    data: orderedLicenses,
    loading: orderedLicensesLoading,
    error: orderedLicensesError,
    postData: orderedLicense,
  } = usePost(orderedLicenseUrl);

  console.log({ orderedLicenses });

  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);

  const onFinish = (values) => {
    try {
      if (type == "upgrade") {
        putAction(values);
        !putLoading &&
          showToast("License updated successfully!", "success", faCheckCircle);
        navigate("/setUpgrade");
      } else {
        postAction(values);
        console.log({ postData });
        !postLoading &&
          showToast("License created successfully!", "success", faCheckCircle);
        navigate("/setInitial");
      }
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  useEffect(() => {
    if (data) {
      setCustomerInfo(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    if (customerInfo) {
      InfoAction({ email: customerInfo.email });
    }
  }, [customerInfo]);

  useEffect(() => {
    if (customerInfo) {
      orderedLicense({ email: customerInfo.email });
    }
  }, [customerInfo]);

  useEffect(() => {
    try {
      if (InfoData && customerInfo) {
        form.setFieldsValue({
          Mac: customerInfo.mac,
          Product: Number(customerInfo.product),
          Model: InfoData?.[0].Model,
          Type: String(InfoData?.[0].Type),
          Options: InfoData?.[0].Options,
          OemNo: InfoData?.[0].OemNo,
          Camera: String(InfoData?.[0].Camera),
          Channel: String(InfoData?.[0].Channel),
          Lockset: String(InfoData?.[0].Lockset),
          Intercom: String(InfoData?.[0].Intercom),
          Subnode: String(InfoData?.[0].Subnode),
          ContLock: String(InfoData?.[0].ContLock),
        });
      }
    } catch (error) {
      if (!InfoLoading) {
        console.log({ error });
        showToast("Error fetching data!", "error", faWarning);
      }
    }
  }, [InfoData, InfoLoading, form]);

  const breadcrumbItems =
    type !== "upgrade"
      ? [
          {
            label: "License Creation",
            href: "/setInitial",
          },
        ]
      : [
          {
            label: "License Upgrade",
            href: "/setUpgrade",
          },
        ];

  const buttonsData =
    type === "upgrade"
      ? [
          {
            label: "License Select",
            icon: <FolderOpenOutlined />,
            onClick: () => {
              setShowCommonModal(true);
            },
          },
          {
            label: "Apply",
            icon: <CheckCircleOutlined />,
            onClick: () => form.submit(),
          },
        ]
      : [
          {
            label: "Apply",
            icon: <CheckCircleOutlined />,
            onClick: () => form.submit(),
          },
        ];

  return (
    <>
      <CommonModal
        title="License Select"
        content={
          <LicenseUpgradeSelectForm
            setSelectedRow={setSelectedRow}
            orderedLicenses={orderedLicenses}
          />
        }
        isVisible={showCommonModal}
        onClose={() => setShowCommonModal(false)}
        onConfirm={() => {
          form.setFieldsValue({
            Options: individualLicenseData?.data?.Options
              ? individualLicenseData?.data?.Options
              : 0,
            Product: individualLicenseData.data.Product
              ? individualLicenseData.data.Product
              : 0,
            Model: individualLicenseData.data.Model
              ? individualLicenseData.data.Model
              : 0,
            Type: individualLicenseData.data.Type
              ? String(individualLicenseData.data.Type)
              : 0,
            OemNo: individualLicenseData.data.OemNo,
            Camera: String(individualLicenseData.data.Camera),
            Channel: String(individualLicenseData.data.Channel),
            Lockset: String(individualLicenseData.data.Lockset),
            Facegate: String(individualLicenseData.data.Facegate),
            Subnode: String(individualLicenseData.data.Subnode),
            ContLock: String(individualLicenseData.data.ContLock),
            Intercom: String(individualLicenseData.data.Intercom),
          });
          setShowCommonModal(false);
        }}
      />

      <Form
        validateTrigger={"onChange"}
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        layout={"vertical"}
        autoComplete={"off"}
      >
        <div className="px-8 ">
          <div className="flex justify-between mt-8">
            <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
            <CommonButton buttonsData={buttonsData} />
          </div>
          {!InfoLoading && (
            <>
              <div className="mt-6 rounded-lg shadow-lg overflow-hidden">
                <div className="bg-gray-100 p-2">
                  <DesktopOutlined />
                  <span className="text-md ml-2">Device</span>
                </div>
                <div className="p-2">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput label="MAC" name="Mac" disable={true} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomSelect
                        label="Product"
                        name="Product"
                        options={modifiedProducts}
                        placeholder="Please select"
                        disabled={type !== "upgrade"}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomSelect
                        label="Model"
                        name="Model"
                        options={modifiedModels}
                        placeholder="Please select"
                        disabled={type !== "upgrade"}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Type"
                        name="Type"
                        disabled={type !== "upgrade"}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomCheckbox
                        label="Options"
                        name="Options"
                        options={modifiedOp}
                        disabled={type !== "upgrade"}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomSelect
                        label="OEM"
                        name="OemNo"
                        options={modifiedOems}
                        placeholder="Please select"
                        disabled={type !== "upgrade"}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Camera"
                        name="Camera"
                        disabled={type !== "upgrade"}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Channel"
                        name="Channel"
                        disabled={type !== "upgrade"}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Lockset"
                        name="Lockset"
                        disabled={type !== "upgrade"}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Intercom"
                        name="Intercom"
                        disabled={type !== "upgrade"}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Subnode"
                        name="Subnode"
                        disabled={type !== "upgrade"}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Trilogy Lockset"
                        name="ContLock"
                        disabled={type !== "upgrade"}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </div>
      </Form>
    </>
  );
};
