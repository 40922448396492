import React, { useState, useEffect } from "react";
import { Button, Row, Col, Input, Table, Select } from "antd";
import { useNavigate } from "react-router-dom";
import useGet from "../../../global/hooks/useGet";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { getModifiedResponse } from "../../../global/utils/responseModifier";

const columns = [
  {
    title: "No",
    dataIndex: "LicenseNo",
  },
  {
    title: "License Name",
    dataIndex: "LicenseName",
  },
  {
    title: "Product",
    dataIndex: "Product",
  },
  {
    title: "Model",
    dataIndex: "Model",
  },
  {
    title: "Type",
    dataIndex: "Type",
  },
  {
    title: "OEM",
    dataIndex: "OemNo",
  },
];

const initialFilterState = {
  LicenseName: "",
  Product: "",
  Model: "",
  Type: "",
  OemNo: "",
};

export const LicenseSelectForm = ({ setSelectedRow, type }) => {
  const [listData, setListData] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [params, setParams] = useState({
    limit: 10,
    sort_by: "LicenseNo",
    order: "desc",
  });

  // to get table data from API server
  const query = new URLSearchParams(params).toString();
  const licenseUrl = query
    ? `${process.env.REACT_APP_API_URL}/api/license/all?${query}`
    : `${process.env.REACT_APP_API_URL}/api/license/all`;
  const {
    data: licenseData,
    loading: licenseDataLoading,
    error: licenseDataError,
  } = useGet(licenseUrl);

  const optionUrl = `${process.env.REACT_APP_API_URL}/api/license/getoptions`;

  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedLicenseTypes = getModifiedResponse(optionData, "licensetypes");
  const modifiedProducts = getModifiedResponse(optionData, "products");
  const modifiedModels = getModifiedResponse(optionData, "models");
  const modifiedOems = getModifiedResponse(optionData, "oems");

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRows);
    },
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const handleFilter = (e) => {
    const filterDict = {};
    for (const key in filter) {
      if (
        filter[key] !== null &&
        filter[key] !== "" &&
        filter[key] !== undefined
      ) {
        filterDict[key] = filter[key];
      }
    }
    const isEmpty = JSON.stringify(filterDict) === "{}";

    if (isEmpty) {
      return;
    } else {
      const params = {
        limit: 10,
        sort_by: "LicenseNo",
        order: "desc",
        ...filterDict,
      };
      setParams(params);
    }
  };

  const handleFilterChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setFilter({ ...filter, [inputName]: inputValue });
  };

  // useEffect(() => {
  //   if (data && !loading) {
  //     const newData = data?.data?.map((el) => ({
  //       ...el,
  //       key: el?.LicenseNo,
  //     }));
  //     setListData(newData);
  //   }
  // }, [data, loading]);

  useEffect(() => {
    if (licenseData && !licenseDataLoading) {
      const filteredData = licenseData?.data?.filter(
        (item) => item?.LicenseType === type
      );
      const newData = filteredData?.map((el) => ({
        ...el,
        key: el?.LicenseNo,
      }));
      setListData(newData);
    }
  }, [licenseData, licenseDataLoading]);

  return (
    <div className="">
      <div className="mt-3 ">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="LicenseName"
              placeholder="Name"
              value={filter.LicenseName}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Product"
              optionFilterProp="Product"
              value={filter.Product || undefined}
              onChange={(value) => setFilter({ ...filter, Product: value })}
              onSearch={onSearch}
              options={modifiedProducts}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Model"
              optionFilterProp="Model"
              value={filter.Model || undefined}
              onChange={(value) => setFilter({ ...filter, Model: value })}
              onSearch={onSearch}
              options={modifiedModels}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              placeholder="Type"
              value={filter.Type}
              onChange={handleFilterChange}
              name="Type"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="OEM"
              optionFilterProp="OemNo"
              value={filter.OemNo || undefined}
              onChange={(value) => setFilter({ ...filter, OemNo: value })}
              onSearch={onSearch}
              options={modifiedOems}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </div>

      <div className="my-4">
        <Button
          type="primary"
          onClick={handleFilter}
          className="mr-2 w-[100px] h-[30px]"
        >
          <CheckCircleOutlined />
          Apply
        </Button>
        <Button
          type="primary"
          className="w-[100px] h-[30px]"
          onClick={() => {
            setFilter(initialFilterState);
            const params = {
              limit: 10,
            };
            setParams(params);
          }}
        >
          <SyncOutlined />
          Reset
        </Button>
      </div>
      <Table
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        columns={columns}
        dataSource={listData}
      />
    </div>
  );
};
