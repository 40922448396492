import { useEffect, useState } from "react";
import { GlobalTable } from "../../../global/components/GlobalTable";
import { useNavigate, Link } from "react-router-dom";
import { Spinner } from "../../../global/components/Spinner";
import useGet from "../../../global/hooks/useGet";
import { DeviceColumns } from "./Columns";
import ConfirmModal from "../../../global/components/ConfirmModal";
import useDelete from "../../../global/hooks/useDelete";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { showToast } from "../../../global/utils/toast";
import { DeviceFilter } from "../Components/FilterComponent";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";

const initialFilterState = {
  DeviceNo: "",
  Mac: "",
  Product: "",
  Model: "",
  Type: "",
  OemNo: "",
  Expired: "",
};

export const Device = () => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [listData, setListData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filter, setFilter] = useState(initialFilterState);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterNoValueText, setFilterNoValueText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState({
    limit: 10,
    sort_by: "DeviceNo",
    order: "desc",
  });

  const navigate = useNavigate();

  const breadcrumbItems = [
    {
      label: "Device",
      href: "/devicelist",
    },
  ];

  // to get table data from API server
  const query = new URLSearchParams(params).toString();
  const url = query
    ? `${process.env.REACT_APP_API_URL}/api/device/all?${query}`
    : `${process.env.REACT_APP_API_URL}/api/device/all`;
  const { data, loading, error, refetch } = useGet(url);

  let deleteUrl;
  selectedRowKeys.length > 1
    ? (deleteUrl = `${process.env.REACT_APP_API_URL}/api/devices/delete`)
    : (deleteUrl = `${process.env.REACT_APP_API_URL}/api/device/delete/${selectedRowKeys}`);

  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
    deleteMultiple,
  } = useDelete(deleteUrl);

  const paginationConfig = {
    current: pagination.current, //current page
    pageSize: pagination.pageSize, // items per page
    total: data?.count, // total items
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 30],
    showTotal: (total, range) => `Total ${total} items ${range[0]}-${range[1]}`,
    onChange: (page, pageSize) => {
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
      });

      const updatedParams = {
        ...params,
        limit: pageSize,
        page: page,
      };
      setParams(updatedParams);
    },
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // navigate(`/oemlist/${newSelectedRowKeys}`);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRow = (record) => {
    return {
      onClick: (event) => {
        navigate(`/device/info/${record?.DeviceNo}`);
      },
    };
  };

  const handleFilterChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setFilter({ ...filter, [inputName]: inputValue });
  };

  const handleFilter = (e) => {
    const filterDict = {};
    for (const key in filter) {
      if (
        filter[key] !== null &&
        filter[key] !== "" &&
        filter[key] !== undefined
      ) {
        filterDict[key] = filter[key];
      }
    }
    const isEmpty = JSON.stringify(filterDict) === "{}";

    if (isEmpty) {
      setFilterNoValueText("No value exists!");
    } else {
      const params = {
        limit: 10,
        sort_by: "DeviceNo",
        order: "desc",
        ...filterDict,
      };
      setParams(params);
    }
  };

  // reset
  const handleReset = (e) => {
    setFilter(initialFilterState);

    const params = {
      limit: 10,
      order: "desc",
    };
    setParams(params);
  };

  const onDeleteConfirm = async () => {
    try {
      if (selectedRowKeys.length === 1) {
        await deleteData();
      } else {
        await deleteMultiple({ ids: selectedRowKeys });
      }
    } catch (error) {
      showToast("Failed!", "error", faWarning);
    }
    setShowModal(false);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      showToast(deletedData?.message, "success", faCheckCircle);
      refetch();
    } else if (deletedDataError) {
      showToast(deletedDataError?.response?.data?.message, "error", faWarning);
    }
  }, [deletedData, deletedDataError]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data?.data?.map((el) => ({
        ...el,
        key: el?.DeviceNo,
      }));
      setListData(newData);
    }
  }, [data, loading]);

  return loading ? (
    <div className="w-full flex items-center justify-center h-screen">
      <Spinner />
    </div>
  ) : (
    <div>
      <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
      <div className="mt-4 p-4 bg-white rounded-md border border-gray-200">
        <DeviceFilter
          filter={filter}
          handleFilterChange={handleFilterChange}
          handleFilter={handleFilter}
          handleReset={handleReset}
          setShowModal={setShowModal}
          selectedRowKeys={selectedRowKeys}
          setFilter={setFilter}
        />
        <ConfirmModal
          showModal={showModal}
          setShowModal={setShowModal}
          onDeleteConfirm={onDeleteConfirm}
          setSelectedRowKeys={setSelectedRowKeys}
        />
        <GlobalTable
          columns={DeviceColumns(
            hoveredRow,
            setShowModal,
            setSelectedRowKeys,
            setParams,
            params
          )}
          dataSource={listData}
          rowSelection={rowSelection}
          pagination={paginationConfig}
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: () => setHoveredRow(rowIndex),
              onMouseLeave: () => setHoveredRow(null),
              ...onRow?.(record, rowIndex),
            };
          }}
        />
      </div>
    </div>
  );
};
