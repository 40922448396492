import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox } from "antd";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { showToast } from "../utils/toast";
import AuthLayout from "./AuthLayout";
import "../styles/login.css";
import useOemData from "../hooks/useOemData";
import { Spinner } from "../components/Spinner";
import usePostLogin from "../hooks/usePostLogin";

const Login = () => {
  const postUrl = `${process.env.REACT_APP_API_URL}/api/auth/login/`;
  const { oemNo, oemData, loading: oemDataLoading } = useOemData();

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePostLogin(postUrl);

  const navigate = useNavigate();

  useEffect(() => {
    if (postData) {
      sessionStorage.setItem("userData", JSON.stringify(postData));
      showToast("Successfully logged in!", "success", faCheckCircle);

      const { role_name } = postData;

      if (role_name === "SuperAdmin") {
        navigate("/devicelist");
      } else if (
        ["OemAdmin", "OemUser", "DealerAdmin", "DealerUser"].includes(role_name)
      ) {
        navigate("/orderlist");
      } else {
        showToast(
          "Login failed. Please check your credentials and try again.",
          "error",
          faWarning
        );
        navigate("/");
      }
    }
    console.log({ postError });
    if (postError) {
      showToast(
        "Login failed. Please check your credentials and try again.",
        "error",
        faWarning
      );
    }
  }, [postData, postError]);

  const sendLocalTime = async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeStamp = Math.floor(Date.now() / 1000);

    const url = `${process.env.REACT_APP_API_URL}/api/time/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        timestamp: timeStamp, // Send as ISO string
        timezone: timeZone,
      }),
    });

    const data = await response.json();
  };

  useEffect(() => {
    sendLocalTime();
  }, []);

  // on submit handler.
  const onSubmitHandler = async (values) => {
    try {
      await postAction(values);
    } catch (error) {
      showToast(
        "Login failed. Please check your credentials and try again.",
        "error",
        faWarning
      );
    }
  };

  return oemDataLoading ? (
    <div className="w-full flex items-center justify-center h-screen">
      <Spinner />
    </div>
  ) : (
    <AuthLayout>
      <div className="border rounded-[4px] p-10 bg-white w-[418px]">
        <img
          // src={`oem/${oemNo}/images/mainLogo.png`}
          // src={require(`../../oem/${oemNo}/images/mainLogo.png`)}
          src={require(`../../oem/${oemNo}/images/mainLogo.png`)}
          alt={oemData?.name}
          className="w-[170px] mb-6 mx-auto"
        />
        <Form
          autoComplete="off"
          requiredMark={false}
          layout="vertical"
          onFinish={onSubmitHandler}
        >
          <Form.Item
            label="Username"
            name="userId"
            className="my-input"
            rules={[
              {
                required: true,
                message: "Please input your userId!",
              },
            ]}
          >
            <Input className="h-[32px] shadow-lg !bg-white" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            className="my-input"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password className="h-[32px] shadow-lg !bg-white" />
          </Form.Item>
          <div className="flex justify-between items-center mb-2">
            <Form.Item
              name="remember"
              valuePropName="checked"
              className="!mb-0"
            >
              <Checkbox className="text-[#374151] text-[14px] leading-[20px] font-[Poppins]">
                Remember me
              </Checkbox>
            </Form.Item>
            <div>
              <Link
                to="/forgot-password"
                className="text-[#374151] hover:!text-customColor text-[14px] leading-[20px] font-[Poppins]"
              >
                Forgot your Password
              </Link>
            </div>
          </div>
          <Form.Item>
            <Button
              htmlType="submit"
              className="w-full bg-customColor text-white hover:!bg-white hover:!text-customColor border-none font-medium text-[14px] !rounded !py-4 h-[35px]"
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthLayout>
  );
};

export default Login;
