import React from "react";
import useOemData from "../hooks/useOemData";

const AuthLayout = ({ children }) => {
  const { oemNo, oemData } = useOemData();

  return (
    <div className=" grid grid-cols-1 lg:grid-cols-2  min-h-screen  lg:bg-white relative overflow-hidden bg-customColor">
      <div className="hidden lg:block bg-customColor relative z-10">
        <div className="absolute inset-0 bg-primary ml-8">
          {/* Decorative circles */}
          <div className="absolute top-2 left-1/4 w-32 h-32 rounded-full border-4 border-white opacity-10 animate-grow"></div>
          <div className="absolute bottom-1 right-1/4 w-48 h-48 rounded-full border-4 border-white opacity-10 animate-grow-slow"></div>
          <div className="absolute top-3/4 right-0 w-24 h-24 rounded-full bg-white opacity-10 animate-grow"></div>
          <div className="absolute bottom-0 left-0 w-20 h-20 rounded-full border-4 border-white opacity-10 animate-grow"></div>
          <div className="absolute top-0 right-0 w-24 h-24 rounded-full border-4 border-white opacity-10 animate-grow"></div>
        </div>
        <div className="absolute inset-0 flex flex-col opacity-90 gap-3 items-center justify-center z-20">
          <img
            // src={`oem/${oemNo}/images/LoginBg.png`}
            src={require(`../../oem/1/images/LoginBg.png`)}
            alt="Decorative"
            className="object-cover w-[542px] h-[492px] rounded-[6px]"
          />
          {/* <img
              onClick={() => setShowPreview(true)}
              id="preview"
              className={classNames('w-full h-[60vh] object-contain', '')}
              src={IMAGE_URL + previewSrc}
               src={Base64ImageUrl(previewSrc as string)}
              alt=""
            /> */}
        </div>
      </div>

      <div className="w-full flex items-center justify-center z-20">
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
