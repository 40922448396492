import { useEffect, useState } from "react";
import axios from "axios";

const useOemData = () => {
  const [oemNo, setOemNo] = useState(0);
  const [oemData, setOemData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getOemInfoUrl = `${process.env.REACT_APP_API_URL}/api/oem/OemNo`;
  const domain = window.location.hostname;

  // Fetch OEM Info Data using axios
  useEffect(() => {
    const fetchOemInfoData = async () => {
      try {
        setLoading(true);
        // const token = JSON.parse(sessionStorage.getItem("userData"));
        // const { access_token } = token;
        const response = await axios.post(
          getOemInfoUrl,
          { domain },
          {
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${access_token}`,
            },
          }
        );
        const oemInfoData = response.data;
        if (oemInfoData?.OemNo) {
          setOemNo(oemInfoData.OemNo);
        }
      } catch (err) {
        console.error("Error fetching OEM Info Data:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOemInfoData();
  }, []);

  // Fetch OEM Data based on the fetched OEM No
  useEffect(() => {
    const fetchOemData = async () => {
      if (oemNo) {
        console.log(oemNo);
        try {
          const data = await import(`../../oem/${oemNo}/data.json`);
          setOemData(data);
        } catch (error) {
          console.error("Error fetching OEM data:", error);
          setOemData({
            name: "Generic",
            description: "Generic",
            color: "#b90704",
          });
        }
      }
    };

    fetchOemData();
  }, [oemNo, loading]);

  return { oemNo, oemData, loading };
};

export default useOemData;
