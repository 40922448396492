import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const LicenseColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params
) => {
  return [
    {
      title: "",
      dataIndex: "",
      width: 20,
    },
    {
      title: "No",
      dataIndex: "OptionNo",
      key: "OptionNo",
      sorter: (a, b) => a?.OptionNo - b?.OptionNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OptionNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OptionNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Name",
      dataIndex: "OptionName",
      key: "OptionName",
      sorter: (a, b) => a?.OptionName?.length - b?.OptionName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OptionName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OptionName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Description",
      dataIndex: "OptionDesc",
      key: "OptionDesc",
      sorter: (a, b) => a?.OptionDesc?.length - b?.OptionDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OptionDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OptionDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "",
      key: "action",
      width: "0%",
      fixed: "right",
      render: (text, record, rowIndex) =>
        hoveredRow === rowIndex ? (
          <div className="action-buttons">
            <div className="">
              <Link
                to={`/licenseoption/edit/${record?.OptionNo}`}
                onClick={(e) => e.stopPropagation()}
                className="text-[#50565E] hover:bg-gray-200 !text-[#50565E] px-2 py-1 rounded-[4px]"
              >
                <EditOutlined />
              </Link>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(true);
                setSelectedRowKeys([record?.OptionNo]);
              }}
              className="hover:cursor-pointer"
            >
              <DeleteOutlined className="text-[#50565E] hover:bg-gray-200 px-2 py-2 rounded-[4px]" />
            </div>
          </div>
        ) : null,
    },
  ];
};
