import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography } from "antd";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo/mainLogo.png";
import { showToast } from "../utils/toast";
import AuthLayout from "./AuthLayout";
import usePostLogin from "../hooks/usePostLogin";

export const CustomerLogin = () => {
  const location = useLocation();
  // Extract query parameters using URLSearchParams
  const queryParams = new URLSearchParams(location.search);
  const mac = queryParams.get("mac");
  const product = queryParams.get("product");

  const [form] = Form.useForm();
  const securityCodeUrl = `${process.env.REACT_APP_API_URL}/api/auth/cuscode/`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/auth/cuslogin/`;
  const {
    data: codeData,
    loading: codeLoading,
    error: codeError,
    postData: sendCode,
  } = usePostLogin(securityCodeUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePostLogin(postUrl);

  const navigate = useNavigate();

  // Handle requesting the security code
  const handleRequestCode = () => {
    const email = form.getFieldValue("email");
    if (email) {
      sendCode({ email });
      !codeLoading &&
        showToast("Successfully sent the code!", "success", faCheckCircle);
    } else {
      showToast("Please input your email first!", "error", faWarning);
    }
  };

  useEffect(() => {
    if (postData) {
      sessionStorage.setItem("userData", JSON.stringify(postData));
      showToast("Successfully logged in!", "success", faCheckCircle);
      if (location.pathname.includes("upgrade")) {
        navigate("/setUpgrade");
      } else {
        navigate("/setInitial");
      }
    }
    if (postError) {
      showToast(
        "Login failed. Please check your credentials and try again.",
        "error",
        faWarning
      );
    }
  }, [postData, postError]);

  // on submit handler.
  const onSubmitHandler = async (values) => {
    try {
      const customerInfo = {
        email: values.email,
        mac,
        product,
      };
      sessionStorage.setItem("customerInfo", JSON.stringify(customerInfo));
      await postAction(values);
    } catch (error) {
      showToast(
        "Login failed. Please check your email and try again.",
        "error",
        faWarning
      );
    }
  };

  return (
    <AuthLayout>
      <div className="border rounded-[4px] p-10 bg-white w-[418px]">
        <img src={logo} alt="Sicunet" className="w-[170px] mb-6 mx-auto" />
        <p className="text-sm text-gray-700 mb-2">
          Enter the Email that you registered with your account.
        </p>
        <Form
          form={form}
          layout={"vertical"}
          size={"middle"}
          autoComplete={"off"}
          validateTrigger={"onChange"}
          requiredMark={false}
          onFinish={onSubmitHandler}
        >
          <div>
            <Form.Item
              label="Email"
              name="email"
              className="my-input"
              style={{ marginBottom: "3px" }}
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
                {
                  type: "email",
                  message: "Please enter a valid email!",
                },
              ]}
            >
              <Input className="h-[32px] shadow-lg !bg-white" />
            </Form.Item>
            <Form.Item
              label="Security Code"
              name="security_code"
              className="my-input"
              rules={[
                {
                  required: true,
                  message: "Please input your security code!",
                },
              ]}
              //   style={{ marginBottom: "6px" }}
            >
              <Input.Password className="h-[32px] shadow-lg !bg-white" />
            </Form.Item>
          </div>
          <p
            className="flex justify-center mb-4 text-xs hover:text-customColor cursor-pointer"
            onClick={handleRequestCode}
          >
            Request Security Code
          </p>
          <div className="flex justify-center">
            <Form.Item className="mr-2">
              <Button
                htmlType="submit"
                className="min-w-[100px] bg-customColor text-white hover:!bg-white border-none hover:!text-customColor font-medium text-[14px] !rounded !py-4 h-[35px] "
              >
                Apply
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className="min-w-[100px] bg-[#E9E9E9] text-customColor hover:!bg-customColor hover:!text-white border-none font-medium text-[14px] !rounded !py-4 h-[35px]"
                onClick={() => navigate("/")}
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </AuthLayout>
  );
};
