import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select } from "antd";
import { Link } from "react-router-dom";
import CustomDateTimePicker from "../../../global/components/inputWidgets/CustomDatePicker";

export const OrderedItemFilter = ({
  filter,
  handleFilterChange,
  handleFilter,
  handleReset,
  setShowModal,
  selectedRowKeys,
  setFilter,
}) => {
  const onSearch = (value) => {
    console.log("search:", value);
  };

  return (
    <div>
      <div className="mt-3">
        <Row gutter={[16, 16]}>
          <Col xs={26} sm={26} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="ItemNo"
              placeholder="No."
              value={filter.ItemNo}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={26} sm={26} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="OrderName"
              placeholder="Order"
              value={filter.OrderName}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={26} sm={26} md={12} lg={8} xl={6}>
            <CustomDateTimePicker
              name="OrderTime"
              placeholder="Order Time"
              format="YYYY-MM-DD hh:mm A"
              value={filter.OrderTime}
              onChange={(value) => setFilter({ ...filter, OrderTime: value })}
              // onChange={(value) => console.log(value)}
            />
          </Col>
          <Col xs={26} sm={26} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              placeholder="License"
              value={filter.LicenseName}
              onChange={handleFilterChange}
              name="LicenseName"
            />
          </Col>
          <Col xs={26} sm={26} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              placeholder="Used"
              value={filter.Used}
              onChange={handleFilterChange}
              name="Used"
            />
          </Col>
        </Row>
      </div>
      <div className="fw fl flex justify-between">
        <div className="my-6">
          <Button
            type="primary"
            className="mr-2 w-[100px] h-[30px]"
            onClick={handleFilter}
          >
            <CheckCircleOutlined />
            Apply
          </Button>
          <Button className="reset-button" onClick={handleReset}>
            <SyncOutlined />
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
};
