import React, { useState } from "react";
import { Col, Modal, Row, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";

const { Title } = Typography;
const ConfirmModal = ({
  showModal,
  setShowModal,
  onDeleteConfirm,
  setSelectedRowKeys,
}) => {
  const handleOk = () => {
    onDeleteConfirm();
    setShowModal(false);
  };
  const handleCancel = () => {
    setShowModal(false);
    setSelectedRowKeys && setSelectedRowKeys([]);
  };
  return (
    <>
      <Modal
        // title="Are you sure you want to delete?"
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
        centered
        okButtonProps={{
          className:
            "bg-blue-600 w-[80px] hover:!bg-gray-100 text-white border-none",
        }}
        cancelButtonProps={{
          className:
            "bg-red-600 w-[80px] text-white hover:bg-gray-100 hover:!text-red-600 border-none",
        }}
      >
        <Row className="p-6">
          <Col>
            <div className="text-lg font-medium flex">
              <span className="flex items-center justify-center w-10 h-10 bg-yellow-100 text-yellow-600 rounded-full">
                <WarningOutlined />
              </span>
              <p className="ml-4 mt-2">Do you want to delete?</p>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default ConfirmModal;
