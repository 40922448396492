import { useNavigate, useParams } from "react-router-dom";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { Form, Button, Col, Row } from "antd";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import {
  DesktopOutlined,
  ProductOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import useGet from "../../../global/hooks/useGet";
import { useEffect } from "react";
import { showToast } from "../../../global/utils/toast";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

export const OrderedItemForm = () => {
  const dataa = sessionStorage.getItem("customerInfo");
  const customerInfo = JSON.parse(dataa);
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const url = `${process.env.REACT_APP_API_URL}/api/orderitem/${id}`;
  const oldLicenseUrl = `${process.env.REACT_APP_API_URL}/api/device/${customerInfo?.mac}`;
  const { data, loading, error } = useGet(url);

  const {
    data: deviceData,
    loading: deviceDataLoading,
    error: deviceDataError,
  } = useGet(oldLicenseUrl);

  console.log({ data });

  useEffect(() => {
    if (!id || loading) return;
    const getOrderItemInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            LicenseName: data?.data?.LicenseInfo?.LicenseName,
            OldModel: deviceData?.data?.ModelName,
            Model: data?.data?.LicenseInfo?.ModelName ?? "",
            OldType: deviceData?.data?.Type,
            Type: data?.data?.LicenseInfo?.Type,
            Options: data?.data?.LicenseInfo?.OptionNames
              ? data?.data?.LicenseInfo?.OptionNames
              : "",
            Camera: data?.data?.LicenseInfo?.Camera ?? 0,
            Channel: data?.data?.LicenseInfo?.Channel ?? 0,
            Lockset: data?.data?.LicenseInfo?.Lockset ?? 0,
            Facegate: data?.data?.LicenseInfo?.Facegate ?? 0,
            Subnode: data?.data?.LicenseInfo?.Subnode ?? 0,
            TrilogyLockset: data?.data?.LicenseInfo?.ContLock ?? 0,
            Intercom: data?.data?.LicenseInfo?.Intercom ?? 0,
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getOrderItemInfoById();
  }, [id, data, loading]);

  const breadcrumbItems = [
    {
      label: "Order Item",
      href: "/orderedItems",
    },
    {
      label: "License",
      href: `/license/information`,
    },
  ];

  const buttonsData = [
    {
      label: "List",
      icon: <UnorderedListOutlined />,
      onClick: () => {
        navigate("/orderedItems");
      },
    },
  ];
  return (
    <div className="px-10 mt-8">
      <div className="flex justify-between">
        <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
        <CommonButton buttonsData={buttonsData} />
      </div>
      {!loading && (
        <Form form={form}>
          <div className="mt-6 rounded-lg shadow-lg overflow-hidden">
            <div className="bg-gray-100 p-2">
              <DesktopOutlined />
              <span className="text-md ml-2">License Information</span>
            </div>
            <div className="p-2">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Old Model"
                    name="OldModel"
                    disable={true}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Model" name="Model" disable={true} />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Old Type" name="OldType" disable={true} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Type" name="Type" disable={true} />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Options" name="Options" disable={true} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Camera" name="Camera" disable={true} />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Channel" name="Channel" disable={true} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Lockset" name="Lockset" disable={true} />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Facegate"
                    name="Facegate"
                    disable={true}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Subnode" name="Subnode" disable={true} />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Trilogy Lockset"
                    name="TrilogyLockset"
                    disable={true}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Intercom"
                    name="Intercom"
                    disable={true}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};
