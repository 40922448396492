import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Form, Button, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { Await, useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../../global/utils/toast";
import {
  CheckCircleOutlined,
  CloseOutlined,
  DesktopOutlined,
  FolderOpenOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import CustomCheckbox from "../../../global/components/inputWidgets/CustomCheckBox";
import CustomSwitch from "../../../global/components/inputWidgets/CustomSwitch";
import CustomTextArea from "../../../global/components/inputWidgets/CustomTextArea";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import CommonModal from "../../../global/components/CommonModal";
import { LicenseSelectForm } from "./LicenseSelectForm";
import { getModifiedResponse } from "../../../global/utils/responseModifier";

export const AddByListForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const url = id ? `${process.env.REACT_APP_API_URL}/api/device/${id}` : null;
  const licenseInfoUrl = `${process.env.REACT_APP_API_URL}/api/license/${selectedRow[0]?.LicenseNo}`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/device/getoptions`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/device/multicreate`;

  const { data, loading, error, refetch } = useGet(url);
  const {
    data: individualLicenseData,
    loading: individualLicenseLoading,
    error: individualLicenseError,
  } = useGet(licenseInfoUrl);
  const {
    data: optionsData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  const modifiedProducts = getModifiedResponse(optionsData, "products");
  const modifiedModels = getModifiedResponse(optionsData, "models");
  const modifiedType = getModifiedResponse(optionsData, "type");
  const modifiedOems = getModifiedResponse(optionsData, "oems");
  const modifieOp = getModifiedResponse(optionsData, "options");

  const onFinish = async (values) => {
    try {
      await postAction({
        ...values,
        Expired: values.Expired === true ? 1 : 0,
      });
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  const onReset = () => {
    // form.resetFields();
    navigate("/devicelist");
  };

  //toat for create
  useEffect(() => {
    if (postError) {
      showToast(postError?.response?.data?.message, "error", faWarning);
    } else if (!postLoading && !postError && postData) {
      showToast(postData?.message, "success", faCheckCircle);
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [postError, postData, postLoading]);

  // Set default field value
  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        OemNo: modifiedOems[0]?.value,
        Product: modifiedProducts[0]?.value,
        Model: modifiedModels[0]?.value,
        Camera: "0",
        Channel: "0",
        Lockset: "0",
        Facegate: "0",
        Subnode: "0",
        ContLock: "0",
        Intercom: "0",
      });
    }
  }, [optionsData]);

  const breadcrumbItems = [
    {
      label: "Device",
      href: "/devicelist",
    },
    {
      label: "Add By List",
      href: "/addDeviceByList",
    },
  ];

  const buttonsData = [
    {
      label: "License Select",
      icon: <FolderOpenOutlined />,
      onClick: () => {
        setShowModal(true);
      },
    },
    {
      label: "List",
      icon: <UnorderedListOutlined />,
      onClick: () => {
        navigate("/devicelist");
      },
    },
    {
      label: "Apply",
      icon: <CheckCircleOutlined />,
      onClick: () => form.submit(),
    },
    {
      label: "Cancel",
      icon: <CloseOutlined />,
      onClick: onReset,
    },
  ];

  return (
    <div>
      <CommonModal
        title="License Select"
        content={
          <LicenseSelectForm
            setSelectedRow={setSelectedRow}
            type="Initial License"
          />
        }
        isVisible={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={() => {
          form.setFieldsValue({
            Options: individualLicenseData?.data?.Options,
            Product: individualLicenseData.data.Product,
            Model: individualLicenseData.data.Model,
            Type: String(individualLicenseData.data.Type),
            OemNo: individualLicenseData.data.OemNo,
            Camera: String(individualLicenseData.data.Camera),
            Channel: String(individualLicenseData.data.Channel),
            Lockset: String(individualLicenseData.data.Lockset),
            Facegate: String(individualLicenseData.data.Facegate),
            Subnode: String(individualLicenseData.data.Subnode),
            ContLock: String(individualLicenseData.data.ContLock),
            Intercom: String(individualLicenseData.data.Intercom),
          });
          setShowModal(false);
        }}
      />
      <div className="flex justify-between">
        <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
        <CommonButton buttonsData={buttonsData} />
      </div>
      <>
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div className="mt-6 rounded-lg shadow-lg overflow-hidden">
            <div className="bg-gray-100 p-2">
              <DesktopOutlined />
              <span className="text-md ml-2">Device</span>
            </div>
            <div className="p-2">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomTextArea
                    label="MAC List"
                    name="Mac"
                    placeholder="MAC"
                    rows={4}
                    maxLength={200}
                    required
                  />
                </Col>
              </Row>
            </div>
          </div>

          <div className="mt-6 rounded-lg shadow-lg overflow-hidden">
            <div className="bg-gray-100 p-2">
              <DesktopOutlined />
              <span className="text-md ml-2">License Information</span>
            </div>
            <div className="p-2">
              <Row gutter={[16, 16]} className="mt-2">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="Product"
                    name="Product"
                    options={modifiedProducts}
                    placeholder="Please select"
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="Model"
                    name="Model"
                    options={modifiedModels}
                    placeholder="Please select"
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Type"
                    name="Type"
                    type="number"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomCheckbox
                    label="Options"
                    name="Options"
                    options={modifieOp}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-2">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="Oem"
                    name="OemNo"
                    options={modifiedOems}
                    placeholder="Please select"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Camera"
                    name="Camera"
                    type="number"
                    required
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-2">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Channel"
                    name="Channel"
                    type="number"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Lockset"
                    name="Lockset"
                    type="number"
                    required
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-2">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Facegate"
                    name="Facegate"
                    type="number"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Subnode"
                    name="Subnode"
                    type="number"
                    required
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-2">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Trilogy Lockset"
                    name="ContLock"
                    type="number"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Intercom"
                    name="Intercom"
                    type="number"
                    required
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-2">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  {/* <CustomInput label="Expired" name="Expired" /> */}
                  <CustomSwitch
                    label="Expired"
                    name="Expired"
                    defaultChecked={0}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </>
    </div>
  );
};
