import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const AccountColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params
) => {
  return [
    {
      title: "",
      dataIndex: "",
      width: 20,
    },
    {
      title: "Account No",
      dataIndex: "AccountNo",
      key: "AccountNo",
      sorter: (a, b) => a?.AccountNo - b?.AccountNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="AccountNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "AccountNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "User ID",
      dataIndex: "UserId",
      key: "UserId",
      sorter: (a, b) => a?.UserId - b?.UserId,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="UserId"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "UserId",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Name",
      dataIndex: "AccountName",
      key: "AccountName",
      sorter: (a, b) => a?.AccountName?.length - b?.AccountName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="AccountName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "AccountName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Description",
      dataIndex: "AccountDesc",
      key: "AccountDesc",
      sorter: (a, b) => a?.AccountDesc?.length - b?.AccountDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="AccountDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "AccountDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      sorter: (a, b) => a?.Email?.length - b?.Email?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Email"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Email",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Account Type",
      dataIndex: "AccountType",
      key: "AccountType",
      sorter: (a, b) => a?.AccountType?.length - b?.AccountType?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="AccountType"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "AccountType",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "OEM",
      dataIndex: "OemNo",
      key: "OemNo",
      sorter: (a, b) => a?.OemNo?.length - b?.OemNo?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Dealer",
      dataIndex: "DealerNo",
      key: "DealerNo",
      sorter: (a, b) => a?.DealerNo?.length - b?.DealerNo?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="DealerNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "DealerNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "",
      key: "action",
      width: "0%",
      fixed: "right",
      render: (text, record, rowIndex) =>
        hoveredRow === rowIndex ? (
          <div className="action-buttons">
            <div className="">
              <Link
                to={`/account/edit/${record?.AccountNo}`}
                onClick={(e) => e.stopPropagation()}
                className="text-[#50565E] hover:bg-gray-200 !text-[#50565E] px-2 py-1 rounded-[4px]"
              >
                <EditOutlined />
              </Link>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(true);
                setSelectedRowKeys([record?.AccountNo]);
              }}
              className="hover:cursor-pointer"
            >
              <DeleteOutlined className="text-[#50565E] hover:bg-gray-200 px-2 py-2 rounded-[4px]" />
            </div>
          </div>
        ) : null,
    },
  ];
};
