import { useState } from "react";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Form, Button, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  CloseOutlined,
  DesktopOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

export const OrderItemAddForm = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const itemOptionUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/getoptions`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/create`;

  const {
    data: itemOptionsData,
    loading: itemOptionDataLoading,
    error: itemOptionDataError,
  } = useGet(itemOptionUrl);

  const modifiedCustomers = getModifiedResponse(itemOptionsData, "customers");
  const modifiedLicenses = getModifiedResponse(itemOptionsData, "licenses");

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  const onFinish = async (values) => {
    try {
      const modValues = { ...values, OrderNo: Number(id) };
      await postAction(modValues);
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  //toat for create
  useEffect(() => {
    if (postError) {
      showToast(postError?.message, faWarning);
    } else if (!postLoading && !postError && postData) {
      showToast("Successfully Created!", "success", faCheckCircle);
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [postError, postData, postLoading]);

  const onReset = () => {
    navigate("/orderlist");
  };

  const breadcrumbItems = [
    {
      label: "OrderItem",
      href: "/orderlist",
    },
    {
      label: "Add",
      href: "/orderitem/add",
    },
  ];

  const buttonsData = [
    {
      label: "List",
      icon: <UnorderedListOutlined />,
      onClick: () => {
        navigate("/orderlist");
      },
    },
    {
      label: "Apply",
      icon: <CheckCircleOutlined />,
      onClick: () => form.submit(),
    },
    {
      label: "Cancel",
      icon: <CloseOutlined />,
      onClick: onReset,
    },
  ];

  return (
    <div>
      <div className="flex justify-between">
        <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
        <CommonButton buttonsData={buttonsData} />
      </div>

      {!itemOptionDataLoading && (
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div className="mt-6 rounded-lg shadow-lg overflow-hidden">
            <div className="bg-gray-100 p-2">
              <OrderedListOutlined />
              <span className="text-md ml-2">Order Item</span>
            </div>
            <div className="p-2">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="Customer"
                    name="CustomerNo"
                    options={modifiedCustomers}
                    placeholder="Please select"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="License"
                    name="LicenseNo"
                    options={modifiedLicenses}
                    placeholder="Please select"
                    required
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Count" name="Count" type="number" />
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};
