import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Form, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import { useParams } from "react-router-dom";
import { showToast } from "../../../global/utils/toast";
import { FileTextOutlined } from "@ant-design/icons";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import { formatUnixTime } from "../../../global/utils/unixToLocalTime";

export const DeviceLogInfo = () => {
  const [form] = Form.useForm();
  const { id } = useParams();

  const url = `${process.env.REACT_APP_API_URL}/api/devicelog/${id}`;

  const { data, loading, error, refetch } = useGet(url);

  useEffect(() => {
    if (!id || loading) return;
    const getDeviceInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            LogTime: data?.data?.LogTime
              ? formatUnixTime(data?.data?.LogTime)
              : "",
            AccountNo: data?.data?.AccountName
              ? String(data?.data?.AccountName)
              : "",
            Mac: data?.data?.Mac,
            Product: data?.data?.ProductName,
            Model: data?.data?.ModelName,
            Type: String(data?.data?.Type),
            OemNo: data?.data?.OemName,
            Options: data?.data?.Options,
            Camera:
              data?.data?.Camera != null ? String(data?.data?.Camera) : "",
            Channel:
              data?.data?.Channel != null ? String(data?.data?.Channel) : "",
            Lockset:
              data?.data?.Lockset != null ? String(data?.data?.Lockset) : "",
            Facegate:
              data?.data?.Facegate != null ? String(data?.data?.Facegate) : "",
            Subnode:
              data?.data?.Subnode != null ? String(data?.data?.Subnode) : "",
            ContLock:
              data?.data?.ContLock != null ? String(data?.data?.ContLock) : "",
            Intercom:
              data?.data?.Intercom != null ? String(data?.data?.Intercom) : "",
            LicenseKey: data?.data?.LicenseKey
              ? String(data?.data?.LicenseKey)
              : "",
            Expired: data?.data?.Expired == 0 ? "No" : "Yes",
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getDeviceInfoById();
  }, [id, data, loading, form]);

  const breadcrumbItems = [
    {
      label: "Device Log",
      href: "/deviceloglist",
    },
    {
      label: "Information",
      href: `/devicelog/info/${id}`,
    },
  ];

  return (
    <div>
      <div className="flex justify-between">
        <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
      </div>
      {!loading && (
        <div className="">
          <Form
            validateTrigger={"onChange"}
            form={form}
            // onFinish={onFinish}
            // scrollToFirstError
            layout={"vertical"}
            autoComplete={"off"}
          >
            <div className="mt-6 rounded-lg shadow-lg overflow-hidden">
              <div className="bg-gray-100 p-2">
                <FileTextOutlined />
                <span className="text-md ml-2">Log Information</span>
              </div>
              <div className="p-2">
                <Row gutter={[16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Log Time"
                      name="LogTime"
                      disabled={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Account"
                      name="AccountNo"
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row gutter={[16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput label="MAC" name="Mac" disabled={true} />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Product"
                      name="Product"
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput label="Model" name="Model" disabled={true} />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput label="Type" name="Type" disabled={true} />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Options"
                      name="Options"
                      disabled={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput label="OEM" name="OemNo" disabled={true} />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput label="Camera" name="Camera" disabled={true} />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Channel"
                      name="Channel"
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Lockset"
                      name="Lockset"
                      disabled={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Facegate"
                      name="Facegate"
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Subnode"
                      name="Subnode"
                      disabled={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Trilogy Lockset"
                      name="ContLock"
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Intercom"
                      name="Intercom"
                      disabled={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="License Key"
                      name="LicenseKey"
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Expired"
                      name="Expired"
                      disabled={true}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};
