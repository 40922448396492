import { useEffect, useState } from "react";
import { GlobalTable } from "../../../global/components/GlobalTable";
import { useNavigate, Link } from "react-router-dom";
import { Button, Col, Input, Row } from "antd";
import { Spinner } from "../../../global/components/Spinner";
import useGet from "../../../global/hooks/useGet";
import { LicenseColumns } from "./Columns";
import ConfirmModal from "../../../global/components/ConfirmModal";
import useDelete from "../../../global/hooks/useDelete";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { showToast } from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";

const initialFilterState = { OptionNo: "", OptionName: "" };

export const LicenseOption = () => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [listData, setListData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [filterNoValueText, setFilterNoValueText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState({
    limit: 10,
    sort_by: "OptionNo",
    order: "desc",
  });
  const [pageA, setPageA] = useState();
  const [pageSizeA, setPageSizeA] = useState(8);

  const navigate = useNavigate();

  const breadcrumbItems = [
    {
      label: "License Option",
      href: "/licenseoptionlist",
    },
  ];

  // to get table data from API server
  const query = new URLSearchParams(params).toString();
  const url = query
    ? `${process.env.REACT_APP_API_URL}/api/licenseoption/all?${query}`
    : `${process.env.REACT_APP_API_URL}/api/licenseoption/all`;
  const { data, loading, error, refetch } = useGet(url);

  let deleteUrl;
  selectedRowKeys.length > 1
    ? (deleteUrl = `${process.env.REACT_APP_API_URL}/api/licenseoptions/delete`)
    : (deleteUrl = `${process.env.REACT_APP_API_URL}/api/licenseoption/delete/${selectedRowKeys}`);

  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
    deleteMultiple,
  } = useDelete(deleteUrl);

  const paginationConfig = {
    current: pagination.current, //current page
    pageSize: pagination.pageSize, // items per page
    total: data?.count, // total items
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 30],
    showTotal: (total, range) => `Total ${total} items ${range[0]}-${range[1]}`,
    onChange: (page, pageSize) => {
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
      });

      const updatedParams = {
        ...params,
        limit: pageSize,
        page: page,
      };
      setParams(updatedParams);
    },
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // navigate(`/oemlist/${newSelectedRowKeys}`);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/licenseoption/info/${record?.OptionNo}`);
      },
    };
  };

  const handleFilterChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setFilter({ ...filter, [inputName]: inputValue });
  };

  const handleFilter = (e) => {
    const filterDict = {};
    for (const key in filter) {
      if (
        filter[key] !== null &&
        filter[key] !== "" &&
        filter[key] !== undefined
      ) {
        filterDict[key] = filter[key];
      }
    }
    const isEmpty = JSON.stringify(filterDict) === "{}";

    if (isEmpty) {
      setFilterNoValueText("No value exists!");
    } else {
      const params = {
        limit: 10,
        sort_by: "OptionNo",
        order: "desc",
        ...filterDict,
      };
      setParams(params);
    }
  };

  // reset
  const handleReset = (e) => {
    setFilter(initialFilterState);

    const params = {
      limit: 10,
      order: "desc",
    };
    setParams(params);
  };

  const onDeleteConfirm = async () => {
    try {
      if (selectedRowKeys.length === 1) {
        await deleteData();
      } else {
        await deleteMultiple({ ids: selectedRowKeys });
      }
    } catch (error) {
      showToast("Failed!", "error", faWarning);
    }
    setShowModal(false);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      showToast(deletedData?.message, "success", faCheckCircle);
      refetch();
    } else if (deletedDataError) {
      showToast(deletedDataError?.response?.data?.message, "error", faWarning);
    }
  }, [deletedData, deletedDataError]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data?.data?.map((el) => ({
        ...el,
        key: el?.OptionNo,
      }));
      setListData(newData);
    }
  }, [data, loading]);

  return loading ? (
    <div className="w-full flex items-center justify-center h-screen">
      <Spinner />
    </div>
  ) : (
    <div>
      <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
      <div className="mt-4 p-4 bg-white rounded-md border border-gray-200">
        <div className="mb-4">
          <div className="mt-2">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Input
                  className="input_text_3 mr10"
                  name="OptionNo"
                  placeholder="No."
                  value={filter.OptionNo}
                  onChange={handleFilterChange}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Input
                  className="input_text_3 mr10"
                  placeholder="Name."
                  value={filter.OptionName}
                  onChange={handleFilterChange}
                  name="OptionName"
                />
              </Col>
            </Row>
          </div>

          <div className="fw fl flex overflow-x-auto">
            <div className="flex-shrink-0  mt-4">
              <Button
                type="primary"
                className="mr-2 w-[100px] h-[30px]"
                onClick={handleFilter}
              >
                <CheckCircleOutlined />
                Apply
              </Button>
              <Button className="reset-button" onClick={handleReset}>
                <SyncOutlined />
                Reset
              </Button>
              <Button
                type="primary"
                className="mr-2 w-[100px] h-[30px]"
                onClick={() => navigate("/licenseoption/add")}
              >
                <PlusOutlined />
                Add
              </Button>

              <Button
                type="primary"
                disabled={selectedRowKeys?.length === 0 ? true : false}
                className="w-[100px] h-[30px]"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <DeleteOutlined />
                Delete
              </Button>
            </div>
          </div>
        </div>
        <ConfirmModal
          showModal={showModal}
          setShowModal={setShowModal}
          onDeleteConfirm={onDeleteConfirm}
          setSelectedRowKeys={setSelectedRowKeys}
        />
        <GlobalTable
          columns={LicenseColumns(
            hoveredRow,
            setShowModal,
            setSelectedRowKeys,
            setParams,
            params
          )}
          dataSource={listData}
          rowSelection={rowSelection}
          pagination={paginationConfig}
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: () => setHoveredRow(rowIndex),
              onMouseLeave: () => setHoveredRow(null),
              ...onRow?.(record, rowIndex),
            };
          }}
        />
      </div>
    </div>
  );
};
