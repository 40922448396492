import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const OemInfoColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params
) => {
  return [
    {
      title: "",
      dataIndex: "",
      width: 20,
    },
    {
      title: "Oem Info No",
      dataIndex: "OemInfoNo",
      key: "OemInfoNo",
      sorter: (a, b) => a?.OemInfoNo - b?.OemInfoNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemInfoNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemInfoNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Oem Info Name",
      dataIndex: "OemInfoName",
      key: "OemInfoName",
      sorter: (a, b) => a?.OemInfoName - b?.OemInfoName,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemInfoName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemInfoName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Description",
      dataIndex: "OemInfoDesc",
      key: "OemInfoDesc",
      sorter: (a, b) => a?.OemInfoDesc?.length - b?.OemInfoDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemInfoDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemInfoDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "",
      key: "action",
      width: "0%",
      fixed: "right",
      render: (text, record, rowIndex) =>
        hoveredRow === rowIndex ? (
          <div className="action-buttons">
            <div className="">
              <Link
                to={`/oeminfo/edit/${record?.OemInfoNo}`}
                onClick={(e) => e.stopPropagation()}
                className="text-[#50565E] hover:bg-gray-200 !text-[#50565E] px-2 py-1 rounded-[4px]"
              >
                <EditOutlined />
              </Link>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(true);
                setSelectedRowKeys([record?.OemInfoNo]);
              }}
              className="hover:cursor-pointer"
            >
              <DeleteOutlined className="text-[#50565E] hover:bg-gray-200 px-2 py-2 rounded-[4px]" />
            </div>
          </div>
        ) : null,
    },
  ];
};
