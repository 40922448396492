import { Form, Input } from "antd";
import React, { useState } from "react";

//import icons
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const CustomInput = ({
  label,
  name,
  type,
  defaultValue,
  errorMessage,
  required,
  isEmail,
  placeholder,
  disable,
  onChange,
  ...rest
}) => {
  const [show, setShow] = useState(false);

  // Custom validation rule for email input
  const emailValidationRule = isEmail
    ? {
        type: "email",
        message: "Please enter a valid email address",
      }
    : {};

  // Custom validation rule for number input
  const numberValidationRule =
    type === "number"
      ? {
          validator: (_, value) => {
            // Check if the value is empty or null, resolve validation as valid
            if (value === undefined || value === null || value === "") {
              return Promise.resolve();
            }
            // Check if the value is a valid number
            if (isNaN(value)) {
              return Promise.reject("Input must be a valid number");
            }
            // Ensure the number is not negative
            if (Number(value) < 0) {
              return Promise.reject("Number cannot be negative");
            }

            return Promise.resolve();
          },
        }
      : {};

  return (
    <Form.Item
      label={
        required ? (
          <span>
            {label} <span style={{ color: "red" }}>*</span>
          </span>
        ) : (
          label
        )
      }
      className={"mb-2"}
      name={name}
      initialValue={defaultValue}
      rules={[
        { required: required, message: `${label} is required` },
        emailValidationRule,
        numberValidationRule,
      ]}
      validateStatus={errorMessage ? "error" : ""}
      help={errorMessage}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      required={false}
    >
      {type === "password" ? (
        <Input.Password
          type={show ? "text" : "password"}
          autoComplete="off"
          spellCheck="false"
          {...rest}
          iconRender={(visible) =>
            visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
          }
        />
      ) : (
        <Input
          disabled={disable}
          type={type}
          placeholder={placeholder ?? `${label}`}
          onChange={onChange}
          style={{ color: disable ? "#000000" : "initial" }}
          {...rest}
        />
      )}
    </Form.Item>
  );
};

export default CustomInput;
