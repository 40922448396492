import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Form, Button, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  CloseOutlined,
  ProductOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

export const OrderForm = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_API_URL}/api/order/${id}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/order/create`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/order/update/${id}`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/order/getoptions`;

  const { data, loading, error, refetch } = useGet(url);
  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedAccounts = getModifiedResponse(optionData, "items");

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);

  const onFinish = async (values) => {
    try {
      if (id) {
        await putAction(values);
      } else {
        await postAction(values);
      }
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  //toat for create
  useEffect(() => {
    if (postError) {
      showToast(postError?.response?.data?.message, "error", faWarning);
    } else if (!postLoading && !postError && postData) {
      showToast(postData?.message, "success", faCheckCircle);
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [postError, postData, postLoading]);

  //toat for update
  useEffect(() => {
    if (putError) {
      showToast(putError?.response?.data?.message, "error", faWarning);
    } else if (!putLoading && !putError && putData) {
      showToast(putData?.message, "success", faCheckCircle);
      refetch();
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [putData, putLoading, putError]);

  const onReset = () => {
    // form.resetFields();
    navigate("/orderlist");
  };

  // Set default field value
  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        AccountNo: modifiedAccounts[0]?.value,
      });
    }
  }, [modifiedAccounts]);

  useEffect(() => {
    if (!id || loading) return;
    const getOemInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            AccountNo: data?.data?.AccountNo,
            OrderName: data.data.OrderName,
            OrderDesc: data.data.OrderDesc,
            Amount: data.data.Amount,
            Paid: data.data.Paid,
            OrderTime: data.data.OrderTime,
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getOemInfoById();
  }, [id, data, loading, form]);

  const breadcrumbItems = [
    {
      label: "Order",
      href: "/orderlist",
    },
    {
      label: "Add",
      href: "/order/add",
    },
  ];

  const buttonsData = [
    {
      label: "List",
      icon: <UnorderedListOutlined />,
      onClick: () => {
        navigate("/orderlist");
      },
    },
    {
      label: "Apply",
      icon: <CheckCircleOutlined />,
      onClick: () => form.submit(),
    },
    {
      label: "Cancel",
      icon: <CloseOutlined />,
      onClick: onReset,
    },
  ];

  return (
    <div>
      <div className="flex justify-between">
        <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
        <CommonButton buttonsData={buttonsData} />
      </div>
      {!loading && (
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div className="mt-6 rounded-lg shadow-lg overflow-hidden">
            <div className="bg-gray-100 p-2">
              <ProductOutlined />
              <span className="text-md ml-2">Order</span>
            </div>
            <div className="p-2">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="Account"
                    name="AccountNo"
                    options={modifiedAccounts}
                    placeholder="Please select"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Order Name" name="OrderName" required />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Description" name="OrderDesc" />
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};
