import { formatUnixTime } from "../../../global/utils/unixToLocalTime";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const OrderColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params
) => {
  return [
    {
      title: "",
      dataIndex: "",
      width: 20,
    },
    {
      title: "Order No",
      dataIndex: "OrderNo",
      key: "OrderNo",
      sorter: (a, b) => a?.OrderNo - b?.OrderNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Account",
      dataIndex: "AccountNo",
      key: "AccountNo",
      sorter: (a, b) => a?.AccountNo?.length - b?.AccountNo?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="AccountNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "AccountNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Name",
      dataIndex: "OrderName",
      key: "OrderName",
      sorter: (a, b) => a?.OrderName?.length - b?.OrderName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Description",
      dataIndex: "OrderDesc",
      key: "OrderDesc",
      sorter: (a, b) => a?.OrderDesc?.length - b?.OrderDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      sorter: (a, b) => a?.Amount?.length - b?.Amount?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Amount"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Amount",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Paid",
      dataIndex: "Paid",
      key: "Paid",
      sorter: (a, b) => a?.Paid?.length - b?.Paid?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Paid"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Paid",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Order Time",
      dataIndex: "OrderTime",
      key: "OrderTime",
      render: (text) => <div>{text ? formatUnixTime(text) : ""}</div>,
      sorter: (a, b) => new Date(a.OrderTime) - new Date(b.OrderTime),
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderTime"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderTime",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "",
      key: "action",
      width: "0%",
      fixed: "right",
      render: (text, record, rowIndex) =>
        hoveredRow === rowIndex ? (
          <div className="action-buttons">
            {record?.Paid == "Yes" ? (
              <div>&nbsp;&nbsp;&nbsp;</div>
            ) : (
              <div className="">
                <Link
                  to={`/orderinfo/edit/${record?.OrderNo}`}
                  onClick={(e) => e.stopPropagation()}
                  className="text-[#50565E] hover:bg-gray-200 !text-[#50565E] px-2 py-1 rounded-[4px]"
                >
                  <EditOutlined />
                </Link>
              </div>
            )}
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(true);
                setSelectedRowKeys([record?.OrderNo]);
              }}
              className="hover:cursor-pointer"
            >
              <DeleteOutlined className="text-[#50565E] hover:bg-gray-200 px-2 py-2 rounded-[4px]" />
            </div>
          </div>
        ) : null,
    },
  ];
};

export const OrderItemColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys
) => {
  return [
    {
      title: "",
      dataIndex: "",
    },
    {
      title: "Item No",
      dataIndex: "ItemNo",
      key: "ItemNo",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a?.ItemNo - b?.ItemNo,
      sortIcon: ({ sortOrder }) => <SortIcon sortOrder={sortOrder} />,
    },
    {
      title: "Customer",
      dataIndex: "CustomerNo",
      key: "CustomerNo",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a?.CustomerNo?.length - b?.CustomerNo?.length,
      sortIcon: ({ sortOrder }) => <SortIcon sortOrder={sortOrder} />,
    },
    {
      title: "License",
      dataIndex: "LicenseNo",
      key: "LicenseNo",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a?.LicenseNo - b?.LicenseNo,
      sortIcon: ({ sortOrder }) => <SortIcon sortOrder={sortOrder} />,
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a?.Price?.length - b?.Price?.length,
      sortIcon: ({ sortOrder }) => <SortIcon sortOrder={sortOrder} />,
    },
    {
      title: "Used",
      dataIndex: "Used",
      key: "Used",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a?.Used?.length - b?.Used?.length,
      sortIcon: ({ sortOrder }) => <SortIcon sortOrder={sortOrder} />,
    },
    {
      title: "Used MAC",
      dataIndex: "UsedMac",
      key: "UsedMac",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a?.UsedMac - b?.UsedMac,
      sortIcon: ({ sortOrder }) => <SortIcon sortOrder={sortOrder} />,
    },
    {
      title: "Used Time",
      dataIndex: "UsedTime",
      key: "UsedTime",
      render: (text) => <div>{text ? formatUnixTime(text) : ""}</div>,
      sorter: (a, b) => a?.UsedTime?.length - b?.UsedTime?.length,
      sortIcon: ({ sortOrder }) => <SortIcon sortOrder={sortOrder} />,
    },
    {
      title: "",
      key: "action",
      width: "0%",
      fixed: "right",
      render: (text, record, rowIndex) =>
        hoveredRow === rowIndex ? (
          <div className="action-buttons">
            {record?.Paid == "Yes" ? (
              <div></div>
            ) : (
              <>
                <div className="">
                  <Link
                    to={`/orderitem/edit/${record?.ItemNo}`}
                    // to={`/orderinfo/edit/${record?.OrderNo}`}
                    onClick={(e) => e.stopPropagation()}
                    className="text-[#50565E] hover:bg-gray-200 !text-[#50565E] px-2 py-1 rounded-[4px]"
                  >
                    <EditOutlined />
                  </Link>
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowModal(true);
                    setSelectedRowKeys([record?.ItemNo]);
                  }}
                  className="hover:cursor-pointer"
                >
                  <DeleteOutlined className="text-[#50565E] hover:bg-gray-200 px-2 py-2 rounded-[4px]" />
                </div>
              </>
            )}
          </div>
        ) : null,
    },
  ];
};
