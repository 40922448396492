import SortIcon from "../../../global/components/SortIcon";
import { formatUnixTime } from "../../../global/utils/unixToLocalTime";
export const DeviceColumns = (setParams, params) => {
  return [
    // {
    //   title: "",
    //   dataIndex: "",
    //   width: 20,
    // },
    {
      title: "No",
      dataIndex: "LogNo",
      key: "LogNo",
      sorter: (a, b) => a?.LogNo - b?.LogNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="LogNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "LogNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Log Time",
      dataIndex: "LogTime",
      key: "LogTime",
      render: (text) => <div>{text ? formatUnixTime(text) : ""}</div>,
      sorter: (a, b) => a?.LogTime?.length - b?.LogTime?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="LogTime"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "LogTime",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Account",
      dataIndex: "AccountNo",
      key: "AccountNo",
      sorter: (a, b) => a?.AccountNo?.length - b?.AccountNo?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="AccountNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "AccountNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "MAC",
      dataIndex: "Mac",
      key: "Mac",
      sorter: (a, b) => a?.Mac?.length - b?.Mac?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Mac"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Mac",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Product",
      dataIndex: "Product",
      key: "Product",
      sorter: (a, b) => a?.Product?.length - b?.Product?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Product"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Product",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Model",
      dataIndex: "Model",
      key: "Model",
      sorter: (a, b) => a?.Model?.length - b?.Model?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Model"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Model",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      sorter: (a, b) => a?.Type?.length - b?.Type?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Type"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Type",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "OEM",
      dataIndex: "OemNo",
      key: "OemNo",
      sorter: (a, b) => a?.OemNo?.length - b?.OemNo?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Expired",
      dataIndex: "Expired",
      key: "Expired",
      sorter: (a, b) => a?.Expired?.length - b?.Expired?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Expired"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Expired",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
  ];
};
