import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { Form, Button, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import useDelete from "../../../global/hooks/useDelete";
import ConfirmModal from "../../../global/components/ConfirmModal";

export const AccountForm = ({ type }) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  const [showModal, setShowModal] = useState(false);
  const [accountType, setAccountType] = useState();
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_API_URL}/api/account/${id}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/account/create`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/account/update/${id}`;
  const deleteUrl = `${process.env.REACT_APP_API_URL}/api/account/delete/${id}`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/account/getoptions`;

  const {
    data: optionsData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);
  const modifiedType = getModifiedResponse(optionsData, "roles");
  const modifiedDealer = getModifiedResponse(optionsData, "dealers");
  const modifiedOems = getModifiedResponse(optionsData, "oems");

  const { data, loading, error, refetch } = useGet(url);
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);
  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
  } = useDelete(deleteUrl);

  const onFinish = (values) => {
    try {
      if (id) {
        if (
          loggedInUser.role_name == "DealerAdmin" ||
          loggedInUser.role_name == "DealerUser"
        ) {
          const modifiedValues = {
            ...values,
            OemNo: loggedInUser.oem_no,
            DealerNo: loggedInUser.dealer_no,
          };
          putAction(modifiedValues);
          // !putLoading &&
          //   showToast("Data updated successfully!", "success", faCheckCircle);
          // setTimeout(() => {
          //   navigate(-1);
          // }, 200);
        } else if (
          loggedInUser.role_name == "OemAdmin" ||
          loggedInUser.role_name == "OemUser"
        ) {
          const modifiedValues = {
            ...values,
            OemNo: loggedInUser.oem_no,
          };
          putAction(modifiedValues);
          // !putLoading &&
          //   showToast("Data updated successfully!", "success", faCheckCircle);
          // setTimeout(() => {
          //   navigate(-1);
          // }, 200);
        } else {
          putAction(values);
          // !putLoading &&
          //   showToast("Data updated successfully!", "success", faCheckCircle);
          // setTimeout(() => {
          //   navigate(-1);
          // }, 200);
        }
      } else {
        if (
          loggedInUser.role_name == "DealerAdmin" ||
          loggedInUser.role_name == "DealerUser"
        ) {
          const modifiedValues = {
            ...values,
            OemNo: loggedInUser.oem_no,
            DealerNo: loggedInUser.dealer_no,
          };
          postAction(modifiedValues);
          // !postLoading &&
          //   showToast("Data created successfully!", "success", faCheckCircle);
          // setTimeout(() => {
          //   navigate(-1);
          // }, 200);
        } else if (
          loggedInUser.role_name == "OemAdmin" ||
          loggedInUser.role_name == "OemUser"
        ) {
          const modifiedValues = {
            ...values,
            OemNo: loggedInUser.oem_no,
          };
          postAction(modifiedValues);
          // !postLoading &&
          //   showToast("Data created successfully!", "success", faCheckCircle);
          // setTimeout(() => {
          //   navigate(-1);
          // }, 400);
        } else {
          postAction(values);
          // !postLoading &&
          //   showToast("Data created successfully!", "success", faCheckCircle);
          // setTimeout(() => {
          //   navigate(-1);
          // }, 400);
        }
      }
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  const onReset = () => {
    // form.resetFields();
    navigate("/accountlist");
  };

  const onDeleteConfirm = async () => {
    try {
      await deleteData();
    } catch (error) {
      showToast("Failed!", "error", faWarning);
    }
    setShowModal(false);
  };

  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        AccountType: modifiedType[0]?.value,
      });
    }
  }, [modifiedType]);

  useEffect(() => {
    if (!id || loading) return;
    const getAccountInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            UserId: data?.data?.UserId ? String(data?.data?.UserId) : "",
            AccountName: data.data.AccountName
              ? String(data.data.AccountName)
              : "",
            AccountDesc: data.data.AccountDesc
              ? String(data.data.AccountDesc)
              : "",
            Email: data.data.Email ? String(data.data.Email) : "",
            AccountType: data.data.AccountType ? data.data.AccountType : "",
            OemNo: data.data.OemNo ? data.data.OemNo : "",
            DealerNo: data.data.DealerNo ? data.data.DealerNo : "",
          });
          setAccountType(data.data.AccountType);
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getAccountInfoById();
  }, [id, data, loading, form]);

  //toat for create
  useEffect(() => {
    if (postError) {
      showToast(postError?.response?.data?.message, "error", faWarning);
    } else if (!postLoading && !postError && postData) {
      showToast(postData?.message, "success", faCheckCircle);
      setTimeout(() => {
        navigate(-1);
      }, 400);
    }
  }, [postError, postData, postLoading]);

  //toat for update
  useEffect(() => {
    if (putError) {
      showToast(putError?.response?.data?.message, "error", faWarning);
    } else if (!putLoading && !putError && putData) {
      showToast(putData?.message, "success", faCheckCircle);
      refetch();
      setTimeout(() => {
        navigate(-1);
      }, 400);
    }
  }, [putData, putLoading, putError]);

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      showToast(deletedData?.message, "success", faCheckCircle);
      refetch();
      navigate("/accountlist");
    } else if (deletedDataError) {
      showToast(deletedDataError?.response?.data?.message, "error", faWarning);
    }
  }, [deletedData, deletedDataError]);

  const breadcrumbItems = [
    {
      label: "Account",
      href: "/accountlist",
    },
    {
      label: id ? (type === "info" ? "Information" : "Edit") : "Add",
      href: id
        ? type === "info"
          ? `/account/info/${id}`
          : `/account/edit/${id}`
        : "/account/add",
    },
  ];

  const buttonsData =
    type === "info"
      ? [
          {
            label: "Edit",
            icon: <EditOutlined />,
            onClick: () => {
              navigate(`/account/edit/${id}`);
            },
          },
          {
            label: "Delete",
            icon: <DeleteOutlined />,
            onClick: () => {
              setShowModal(true);
            },
          },
          {
            label: "List",
            icon: <UnorderedListOutlined />,
            onClick: () => {
              navigate("/accountlist");
            },
          },
        ]
      : [
          {
            label: "Apply",
            icon: <CheckCircleOutlined />,
            onClick: () => form.submit(),
          },
          {
            label: "Cancel",
            icon: <CloseOutlined />,
            onClick: onReset,
          },
        ];

  return (
    <div>
      {!loading && (
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div>
            <div className="flex justify-between">
              <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
              <CommonButton buttonsData={buttonsData} />
            </div>
            {!loading && (
              <div className="mt-6 rounded-lg shadow-lg overflow-hidden">
                <div className="bg-gray-100 p-2">
                  <UserOutlined />
                  <span className="text-md ml-2">Account</span>
                </div>
                <div className="p-2">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="User Id"
                        name="UserId"
                        disabled={type === "info"}
                        required
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Password"
                        name="password"
                        type="password"
                        disabled={type === "info"}
                        required
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Name"
                        name="AccountName"
                        disabled={type === "info"}
                        required
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Description"
                        name="AccountDesc"
                        disabled={type === "info"}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} className="mt-2">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Email"
                        name="Email"
                        type="email"
                        isEmail={true}
                        disabled={type === "info"}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomSelect
                        label="Account Type"
                        name="AccountType"
                        options={modifiedType}
                        placeholder="Please select"
                        onChange={(value) => setAccountType(value)}
                        disabled={type === "info"}
                        required
                      />
                    </Col>
                  </Row>
                  {(accountType === 6 ||
                    accountType === 4 ||
                    accountType === 5) &&
                    loggedInUser.role_name == "SuperAdmin" && (
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="OEM"
                            name="OemNo"
                            options={modifiedOems}
                            placeholder="Please select"
                            disabled={type === "info"}
                            required
                          />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="Dealer"
                            name="DealerNo"
                            options={modifiedDealer}
                            placeholder="Please select"
                            disabled={type === "info"}
                            required
                          />
                        </Col>
                      </Row>
                    )}
                  {(accountType === 2 || accountType === 3) &&
                    loggedInUser.role_name == "SuperAdmin" && (
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="OEM"
                            name="OemNo"
                            options={modifiedOems}
                            placeholder="Please select"
                            disabled={type === "info"}
                            required
                          />
                        </Col>
                      </Row>
                    )}
                  {(accountType === 6 ||
                    accountType === 4 ||
                    accountType === 5) &&
                    (loggedInUser.role_name == "OemAdmin" ||
                      loggedInUser.role_name == "OemUser") && (
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="Dealer"
                            name="DealerNo"
                            options={modifiedDealer}
                            placeholder="Please select"
                            disabled={type === "info"}
                            required
                          />
                        </Col>
                      </Row>
                    )}
                </div>
              </div>
            )}
          </div>
        </Form>
      )}
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        onDeleteConfirm={onDeleteConfirm}
      />
    </div>
  );
};
