import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  LockOutlined,
  InfoCircleOutlined,
  OrderedListOutlined,
  ProductOutlined,
  CodepenOutlined,
  FileTextOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Navigate, useLocation } from "react-router-dom";
import { SideBar } from "./SideBar";

// icon handler function
const iconHandler = (icon) => <div>{icon}</div>;

const allMenuItems = {
  SuperAdmin: [
    {
      label: "Device",
      key: "/devicelist",
      icon: iconHandler(<DesktopOutlined />),
    },
    {
      label: "Device Log",
      key: "/deviceloglist",
      icon: iconHandler(<FileTextOutlined />),
    },
    {
      label: "Order",
      key: "/orderlist",
      icon: iconHandler(<ProductOutlined />),
    },
    {
      label: "Order Item",
      key: "/orderitemlist",
      icon: iconHandler(<OrderedListOutlined />),
    },
    {
      label: "License",
      key: "/licenselist",
      icon: iconHandler(<LockOutlined />),
    },
    {
      label: "License Option",
      key: "/licenseoptionlist",
      icon: iconHandler(<PieChartOutlined />),
    },
    {
      label: "Account",
      key: "/accountlist",
      icon: iconHandler(<UserOutlined />),
    },
    {
      label: "Dealer",
      key: "/dealerlist",
      icon: iconHandler(<UsergroupAddOutlined />),
    },
    {
      label: "OEM",
      key: "/oemlist",
      icon: iconHandler(<PieChartOutlined />),
    },
    {
      label: "Oem Info",
      key: "/oeminfolist",
      icon: iconHandler(<InfoCircleOutlined />),
    },
    //   {
    //     label: "Users",
    //     key: "/users",
    //     icon: iconHandler(<TeamOutlined />),
    //     children: [
    //       {
    //         label: "Employees",
    //         key: "/employee",
    //         icon: iconHandler(<UserOutlined />),
    //       },
    //     ],
    //   },
  ],
  OemAdmin: [
    {
      label: "Order",
      key: "/orderlist",
      icon: iconHandler(<ProductOutlined />),
    },
    {
      label: "Order Item",
      key: "/orderitemlist",
      icon: iconHandler(<OrderedListOutlined />),
    },
    {
      label: "Account",
      key: "/accountlist",
      icon: iconHandler(<UserOutlined />),
    },
    {
      label: "Dealer",
      key: "/dealerlist",
      icon: iconHandler(<UserOutlined />),
    },
  ],
  OemUser: [
    {
      label: "Order",
      key: "/orderlist",
      icon: iconHandler(<ProductOutlined />),
    },
    {
      label: "Order Item",
      key: "/orderitemlist",
      icon: iconHandler(<OrderedListOutlined />),
    },
    {
      label: "Account",
      key: "/accountlist",
      icon: iconHandler(<UserOutlined />),
    },
    {
      label: "Dealer",
      key: "/dealerlist",
      icon: iconHandler(<UserOutlined />),
    },
  ],
  DealerAdmin: [
    {
      label: "Order",
      key: "/orderlist",
      icon: iconHandler(<ProductOutlined />),
    },
    {
      label: "Order Item",
      key: "/orderitemlist",
      icon: iconHandler(<OrderedListOutlined />),
    },
    {
      label: "Account",
      key: "/accountlist",
      icon: iconHandler(<UserOutlined />),
    },
  ],
  DealerUser: [
    {
      label: "Order",
      key: "/orderlist",
      icon: iconHandler(<ProductOutlined />),
    },
    {
      label: "Order Item",
      key: "/orderitemlist",
      icon: iconHandler(<OrderedListOutlined />),
    },
    {
      label: "Account",
      key: "/accountlist",
      icon: iconHandler(<UserOutlined />),
    },
  ],
  Customer: [
    {
      label: "Order",
      key: "/orderlist",
      icon: iconHandler(<ProductOutlined />),
    },
    {
      label: "Order Item",
      key: "/orderitemlist",
      icon: iconHandler(<OrderedListOutlined />),
    },
  ],
};

const allProtectedRouteList = {
  SuperAdmin: [
    "/profile",
    "/oemlist",
    "/oem/add",
    "devicelist/add",
    "/devicelog/info/",
    "/device/edit",
    "/device/info",
    "/accountlist",
    "/account/add",
    "/account/edit",
    "/account/info",
    "/dealerlist",
    "/dealer/add",
    "/dealer/edit",
    "/dealer/info",
    "/devicelist",
    "/deviceloglist",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo/edit",
    "/orderinfo/info",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/licenselist",
    "/license/add",
    "/license/edit",
    "/license/info",
    "/licenseoptionlist",
    "/licenseoption/add",
    "/licenseoption/info",
    "/licenseoption/edit",
    "/licenseoption",
    "/oeminfolist",
    "/oeminfo/add",
    "/oem/edit",
    "/oem/info",
    "/oeminfo/edit",
    "/oeminfo/info",
    "/addDeviceByList",
    "/addDeviceByCount",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  OemAdmin: [
    "/profile",
    "/accountlist",
    "/account/edit",
    "/account/info",
    "/account/add",
    "/dealerlist",
    "/dealer/add",
    "/dealer/edit",
    "/dealer/info",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo",
    "/orderitemlist",
    "/orderitem/add/",
    "/orderinfo/edit",
    "/orderinfo/info",
    "orderiteminfo",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  OemUser: [
    "/profile",
    "/accountlist",
    "/account/edit",
    "/account/info",
    "/account/add",
    "/dealerlist",
    "/dealer/add",
    "/dealer/edit",
    "/dealer/info",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo",
    "/orderitemlist",
    "/orderitem/add/",
    "/orderinfo/edit",
    "/orderinfo/info",
    "orderiteminfo",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  DealerAdmin: [
    "/profile",
    "/accountlist",
    "/account/edit",
    "/account/info",
    "/account/add",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo",
    "/orderitemlist",
    "/orderitem/add/",
    "/orderinfo/edit",
    "/orderinfo/info",
    "orderiteminfo",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  DealerUser: [
    "/profile",
    "/accountlist",
    "/account/edit",
    "/account/info",
    "/account/add",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo",
    "/orderitemlist",
    "/orderitem/add/",
    "/orderinfo/edit",
    "/orderinfo/info",
    "orderiteminfo",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  Customer: [
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo",
    "/orderitemlist",
    "/orderitem/add/",
    "/orderinfo/edit",
    "/orderinfo/info",
    "orderiteminfo",
  ],
};

export const PrivateRoute = ({ children }) => {
  // const user = useAuth();
  const user = JSON.parse(sessionStorage.getItem("userData")); // need to change later
  // console.log(user);
  const { pathname } = useLocation();

  // check current route is permitted or not
  const isPermittedRoute =
    user?.role_name &&
    allProtectedRouteList[user.role_name]?.some((item) =>
      pathname?.includes(item)
    );

  // console.log({ user, isPermittedRoute });

  return user && isPermittedRoute ? (
    <SideBar items={allMenuItems[user?.role_name]}>{children} </SideBar>
  ) : (
    <Navigate to={"/"} />
  );
};
